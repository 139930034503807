import {httpRequest} from "../index";

export default class AiFeatures {
    static getIdeas = (app_id, brand_name, words, localization, country_code, type) => {
        return httpRequest('POST', process.env.VUE_APP_API_URL + 'api/ai-features/generate-metadata', {
            app_id: app_id,
            brand_name: brand_name,
            words: words,
            localization: localization,
            country_code: country_code,
            type: type
        });
    }
}