<script setup>

</script>

<template>
<span class="d-flex">
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path d="M11.625 9.8625H9.9975C8.6625 9.8625 7.575 8.7825 7.575 7.44V5.8125C7.575 5.505 7.3275 5.25 7.0125 5.25H4.635C2.9025 5.25 1.5 6.375 1.5 8.385V13.365C1.5 15.375 2.9025 16.5 4.635 16.5H9.0525C10.785 16.5 12.1875 15.375 12.1875 13.365V10.425C12.1875 10.11 11.9325 9.8625 11.625 9.8625Z" fill="#505660"/>
    <path d="M13.3653 1.5H11.8878H11.0703H8.94781C7.25281 1.5 5.88031 2.58 5.82031 4.5075C5.86531 4.5075 5.90281 4.5 5.94781 4.5H8.07031H8.88781H10.3653C12.0978 4.5 13.5003 5.625 13.5003 7.635V9.1125V11.145V12.6225C13.5003 12.6675 13.4928 12.705 13.4928 12.7425C15.1653 12.69 16.5003 11.58 16.5003 9.6225V8.145V6.1125V4.635C16.5003 2.625 15.0978 1.5 13.3653 1.5Z" fill="#505660"/>
    <path d="M8.98547 5.3628C8.75297 5.1303 8.35547 5.2878 8.35547 5.6103V7.5753C8.35547 8.4003 9.05297 9.0753 9.90797 9.0753C10.4405 9.0828 11.183 9.0828 11.8205 9.0828C12.143 9.0828 12.308 8.7078 12.083 8.4828C11.2655 7.6653 9.81047 6.2028 8.98547 5.3628Z" fill="#505660"/>
  </svg>
</span>
</template>

<style scoped lang="scss">

</style>