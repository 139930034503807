<script setup>

</script>

<template>
<span class="circle-plus-icon">
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 0.5C6.1125 0.5 0.5 6.1125 0.5 13C0.5 19.8875 6.1125 25.5 13 25.5C19.8875 25.5 25.5 19.8875 25.5 13C25.5 6.1125 19.8875 0.5 13 0.5ZM18 13.9375H13.9375V18C13.9375 18.5125 13.5125 18.9375 13 18.9375C12.4875 18.9375 12.0625 18.5125 12.0625 18V13.9375H8C7.4875 13.9375 7.0625 13.5125 7.0625 13C7.0625 12.4875 7.4875 12.0625 8 12.0625H12.0625V8C12.0625 7.4875 12.4875 7.0625 13 7.0625C13.5125 7.0625 13.9375 7.4875 13.9375 8V12.0625H18C18.5125 12.0625 18.9375 12.4875 18.9375 13C18.9375 13.5125 18.5125 13.9375 18 13.9375Z" fill="#5D66FE"/>
  </svg>
</span>
</template>

<style scoped lang="scss">
.circle-plus-icon {
  display: flex;

  &--pointer {
    cursor: pointer;
  }
}
</style>