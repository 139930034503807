<script setup>

import ModalCommonContentContainer from "@/components/Modals/ModalCommonContentContainer.vue";
import AiIcon from "@/components/icons/AiIcon.vue";
import AppTitle from "@/components/Billets/AppTitle/index.vue";
import CirclePlusIcon from "@/components/icons/CirclePlusIcon.vue";
import {computed, ref, watch} from "vue";
import WarningIcon from "@/components/icons/WarningIcon.vue";
import {getLocaleCountryCode} from "@/configs/locales-flags";
import translates from "@/api/modules/translates";
import AiFeatures from "@/api/modules/ai-features";
import PreloaderTableSmall from "@/components/UI/PreloaderTableSmall/index.vue";
import ArrowIcon from "@/components/icons/ArrowIcon.vue";
import CopyIcon from "@/components/icons/CopyIcon.vue";
import CircleCheckIcon from "@/components/icons/CircleCheckIcon.vue";
import {copyToClipboard} from "@/views/SearchTerms/Common/methods";

const props = defineProps({
  'app':{},
  'localeCode':{},
  'localeName':{},
  'countryCode':{},
  'type':{
    type: String,
    default: 'title'
  },
  'initWords':{
    type: Array,
    default: () => []
  },
})

const words = ref([...props.initWords])

const wordsTranslates = ref({})
if (words.value.length) {
  translates.translateKeywords(null, props.app.store.key, words.value).then(res => {
    wordsTranslates.value = {
      ...wordsTranslates.value,
      ...res
    }
  })
}

const wordModel = ref('')
const handleAddWord = () => {
  if (!wordModel.value) return
  if (words.value.length >= 30) return

  const split = wordModel.value.split(/[,\s]/)
      .map((word) => word.trim().toLowerCase()).filter(Boolean)

  words.value = words.value.concat(split)
  wordModel.value = ''

  translates.translateKeywords(null, props.app.store.key, split).then(res => {
    wordsTranslates.value = {
      ...wordsTranslates.value,
      ...res
    }
  })
}

const translateWords = ref({})
const handleTranslateWord = (word, checked) => {
  if (checked) {
    translateWords.value[word] = true
  } else {
    delete translateWords.value[word]
  }
}

const handleCheckedAll = (checked) => {
  if (checked) {
    words.value.forEach(word => {
      if (!translateWords.value[word]) {
        translateWords.value[word] = true
      }
    })
  } else {
    translateWords.value = {}
  }
}

const isAllTranslated = computed(() => {
  return words.value.every(word => translateWords.value[word])
})

const brand = ref('')

const totalLength = computed(() => {
  return words.value.reduce((acc, word) => acc + word.length, 0) + brand.value.length + wordModel.value.length
})

const handleChangeWord = (index) => {
  if (!words.value[index]) {
    words.value.splice(index, 1)
    Object.keys(translateWords.value).forEach(word => {
      if (!words.value.includes(word)) {
        delete translateWords.value[word]
      }
    })
  } else {
    translates.translateKeyword(null, props.app.store.key, words.value[index]).then(res => {
      wordsTranslates.value[words.value[index]] = res
    })
  }
}

const state = ref('input')
const isLoading = ref(false)
const variants = ref([])
const variantsTranslates = ref({})
const loadingText = ref(null)
const handleGenerate = () => {
  isLoading.value = true
  loadingText.value = 'Generating...'

  AiFeatures.getIdeas(
      props.app.id,
      brand.value,
      words.value.map(word => ({word, translate: !!translateWords.value[word]})),
      props.localeCode,
      props.countryCode,
      props.type === 'title' ? 'title' : 'subtitle'
  ).then(async (r) => {
    variants.value = r.list
    state.value = 'show-variants'

    loadingText.value = 'Translating...'
    await translates.translatePhrases(props.app.store.key, variants.value, 2).then(res => {
      variantsTranslates.value = res
    }).catch((e) => {
      console.error(e)
    })

    isLoading.value = false
    loadingText.value = null
  }).catch((e) => {
    console.error(e)
    alert('Something went wrong')
    isLoading.value = false
  })
}

const currentEditVariant = ref(null)
const handleEditVariant = (variant) => {
  currentEditVariant.value = variant
}

const handleBack = () => {
  state.value = 'input'
  variants.value = []
  variantsTranslates.value = {}
}

const emit = defineEmits(['apply'])
const handleApply = (variant) => {
  emit('apply', variant)
}
</script>

<template>
  <ModalCommonContentContainer>
    <template #heading>
      <div class="title">
        <AiIcon fill="#fff"/> AI-assistant (beta)
      </div>
    </template>
    <template #content>
      <div v-if="isLoading" class="content d-flex f-j-center f-align-center mt-20" style="min-height: 200px">
        <PreloaderTableSmall :text="loadingText" loader-size="80px"/>
      </div>
      <div v-else class="content">
        <div class="created-for">
          <div class="mb-6">Create {{type === 'title' ? 'title' : 'subtitle'}} for:</div>
          <AppTitle
              :title="app.title"
              :logo="app.logo"
              :store-link="app.store_link"
              :store="app.store.key"
          />
        </div>

        <template v-if="state === 'input'">
          <div class="d-flex f-j-between f-align-center mt-20 f-gap-8 filled-brand">
            <div>Filled brand <i>(optional)</i> and words to use:</div>
            <div class="counter" :class="{red: totalLength > 30}">
              {{ totalLength }}/30
            </div>
          </div>

          <div class="warning-block mt-12 color-800 d-flex f-gap-8" v-if="totalLength > 30">
            <WarningIcon/>
            <span>Your request exceeds 30 characters. You can continue adding words, but the result will be generated selectively</span>
          </div>

          <div class="form-control d-flex f-column f-gap-16 mt-20">
            <div>
              <input placeholder="Brand" v-model="brand"/>
            </div>
            <div class="d-flex f-gap-16 f-align-center">
              <input placeholder="Word to use" v-model="wordModel" @keyup.enter="handleAddWord"/>
              <CirclePlusIcon class="circle-plus-icon--pointer" @click="handleAddWord"/>
            </div>
            <div class="separ"></div>
          </div>

          <div class="mt-20" v-if="words.length">
            <div class="d-flex f-align-center f-gap-8 mb-16 color-800">
              <input id="translateAllWords" type="checkbox" class="common-checkbox" @change="handleCheckedAll($event.target.checked)" :checked="isAllTranslated"/>
              <label for="translateAllWords">
                <span>Mark words assistant should translate to {{localeName}}&nbsp;</span>
                <dynamic-image
                    :key="localeCode"
                    classes="country-flag"
                    :height="12"
                    :country-code="getLocaleCountryCode(localeCode)?.countryCode"/>
              </label>
            </div>
            <div class="d-flex flex-column f-gap-14 words-block">
              <div v-for="(word, i) in words" :key="i" class="d-flex f-align-center f-gap-8">
                <input type="checkbox" class="common-checkbox" @change="handleTranslateWord(word, $event.target.checked)" :checked="translateWords[word]"/>
                <div class="form-control">
                  <input v-model="words[i]" @change="handleChangeWord(i)"/>
                  <div class="mt-8 color-700 font-w-400" v-if="wordsTranslates[word] && wordsTranslates[word] !== word">
                    <dynamic-image
                        key="en"
                        classes="country-flag"
                        :height="12"
                        country-code="US"/>
                    <span>{{wordsTranslates[word]}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex f-j-center">
            <div class="common-btn-purple mt-32" @click="handleGenerate">Generate Ideas</div>
          </div>
        </template>

        <template v-else>
          <div class="d-flex flex-column mt-20 f-gap-16">
            <div v-for="(variant, vIndex) in variants" :key="vIndex" class="variant" @click="handleEditVariant(vIndex)">
              <div class="d-flex f-j-between f-gap-8 f-align-center">
                <div class="w100p">
                  <div v-if="currentEditVariant !== vIndex">{{variant}}</div>
                  <div v-else class="form-control">
                    <input v-model="variants[vIndex]"/>
                  </div>
                </div>
                <div class="d-flex f-gap-6 f-align-center">
                  <div class="variant-actions d-flex f-gap-6 f-align-center">
                    <CopyIcon @click="$event.stopPropagation(); copyToClipboard(variant)" v-tooltip="{ text: 'Copy', position: 'top', onClickText: 'Copied' }"/>
                    <CircleCheckIcon @click="$event.stopPropagation(); handleApply(variant)"/>
                  </div>
                  <div class="counter" :class="{red: variant.length > 30}">
                    {{ variant.length }}/30
                  </div>
                </div>
              </div>

              <div class="mt-8 color-700 font-w-400" v-if="variantsTranslates[variant] && variantsTranslates[variant] !== variant">
                <dynamic-image
                    key="en"
                    classes="country-flag"
                    :height="12"
                    country-code="US"/>
                <span>{{variantsTranslates[variant]}}</span>
              </div>
            </div>
          </div>

          <div class="d-flex f-j-center">
            <div class="common-btn-purple-transparent mt-32" @click="handleBack"><ArrowIcon class="mr-8"/> Back & Reset</div>
          </div>
        </template>

      </div>
    </template>
  </ModalCommonContentContainer>
</template>

<style scoped lang="scss">
:deep(.common-modal-heading) {
  background: var(--primary-default);
  margin: -36px -32px 24px -32px;
  padding: 36px 32px;
  border-radius: 8px 8px 0 0;
}

.title {
  color: #fff;
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 20px;
}

.content {
  width: 460px;
  padding: 4px;

  .created-for {
    font-weight: 600;

    :deep(.title-text) {
      font-weight: 700;
    }
  }

  .filled-brand {
    color: var(--neutral-800);
  }

  .warning-block {
    background: #F7D75880;
    padding: 16px;
    border-radius: 8px;
  }

  .separ {
    height: 1px;
    background: var(--neutral-400);
  }

  .counter {
    color: var(--neutral-600);
    min-width: 43px;
    text-align: right;

    &.red {
      color: var(--secondary-dark-red);
    }
  }

  .variant {
    padding: 4px;
    margin: -4px;
    cursor: pointer;

    .variant-actions {
      display: none;
    }

    &:hover {
      background: var(--Neutral-300, #EFF4FB);

      .variant-actions {
        display: flex;
      }
    }
  }
}
</style>