<script setup>

</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" fill="none">
    <path d="M1.33073 6L14.6641 6" stroke="#5D66FE" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.33203 1L1.38411 5.94792V5.94792C1.35535 5.97668 1.35535 6.02332 1.38411 6.05208V6.05208L6.33203 11" stroke="#5D66FE" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<style scoped lang="scss">

</style>