<script setup>

</script>

<template>
  <span class="d-flex">
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="18" height="18" rx="9" fill="#5D66FE"/>
    <path
        d="M4.80078 9.5602L7.22901 11.9884V11.9884C7.27974 12.0391 7.36197 12.0391 7.41269 11.9884V11.9884L13.2008 6.2002"
        stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  </span>
</template>

<style scoped lang="scss">

</style>