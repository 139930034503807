<template>
  <custom-tooltip direction-horizontal="left"
                  direction-vertical="bottom"
                  :straight="true"
                  :width="isKeywordsMode ? '500px' : '400px'"
                  max-height="505px"
                  :with-padding="true"
                  :delayed-closing="true"
                  :style="{padding: 0}"
                  classes="tool-item hover-bg-green">
    <template v-slot:slotTrigger>
      <template v-if="isLoading">
        <div class="coverage-loader">
          <preloader-table-small></preloader-table-small>
        </div>
      </template>
      <template v-else>
        <div class="meta-coverage-indicator-chart">
          <div class="title" :class="titleIndicatorClass"></div>
          <div class="subtitle" :class="subtitleIndicatorClass"></div>
        </div>
      </template>
    </template>

    <template v-if="!isLoading" v-slot:slotContent>
      <div class="meta-coverage-tooltip">
        <div class="meta-coverage-tooltip-top">
          <div class="title">
            <template v-if="isKeywordsMode">
              Keyword Coverage Analysis
            </template>
            <template v-else>
              Word Coverage Analysis
            </template>
          </div>
          <div class="definitions">
            <template v-if="isKeywordsMode">
              <div><span class="coverage-in-title">in title</span></div>
              <div><span class="coverage-in-subtitle">in subtitle</span></div>
              <div><span class="coverage-in-keywords">in keywords</span></div>
              <div><span class="dark-red">uncovered</span></div>
            </template>
            <template v-else>
              <div><span class="dark-green">T</span> - Title</div>
              <div><span class="dark-green">S</span> - Subtitle</div>
              <div><span class="dark-green">K</span> - Keyword</div>
              <div><span class="dark-red">uncovered</span></div>
            </template>
          </div>
        </div>

        <table>
          <tr v-for="locale in locales">
            <td>
              <div class="locale-block">
                <div class="meta-coverage-indicator-chart">
                  <div class="title"
                       :class="[
                           coverageForTableIndicators?.title[locale]?.level,
                           isFullInTitleAndInSubtitleOrKeywords(locale) && this.isPartial('title', locale) ? 'inBothMetas' : '',
                       ]">
                  </div>
                  <div class="subtitle"
                       :class="[
                           this.isFullInTitleAndInSubtitleOrKeywords(locale) || this.isFullInSubtitleAndKeywords(locale) ? 'full' : '',
                           coverageForTableIndicators?.sub_title[locale]?.level,
                           coverageForTableIndicators?.keywords[locale]?.level,
                           !this.isFull('title', locale) && this.isFullInTitleAndInSubtitleOrKeywords(locale) && this.isPartial('title', locale) ? 'inBothMetas' : ''
                       ]">
                  </div>
                </div>
                <div>
                  {{ localesTitles[locale] }}
                </div>
              </div>
            </td>
            <td v-if="!isKeywordsMode">{{ word }}</td>
            <td v-if="coverage.title[locale]">
              <div class="coverage">
                <img src="@/assets/images/icons/app_store_gray.svg"
                     width="16"
                     height="16">
                <span v-if="isKeywordsMode" v-html="highlightWord(locale, coverage)"></span>
                <template v-else>
                  <div :class="coverage.title[locale].level === 'zero' ? 'dark-red' : 'dark-green'">
                    T
                  </div>
                  <div v-if="coverage.sub_title[locale]"
                       :class="coverage.sub_title[locale].level === 'zero' ? 'dark-red' : 'dark-green'">
                    S
                  </div>
                  <div v-if="coverage.keywords[locale]"
                       :class="coverage.keywords[locale].level === 'zero' ? 'dark-red' : 'dark-green'">
                    K
                  </div>
                </template>
              </div>
            </td>
            <td v-if="redactionCoverage">
              <div class="coverage">
                <svg-icon icon="document" />
                <span v-if="isKeywordsMode" v-html="highlightWord(locale, redactionCoverage)"></span>
                <template v-else>
                  <div :class="redactionCoverage?.title[locale]?.level === 'zero' ? 'dark-red' : 'dark-green'">T</div>
                  <div :class="redactionCoverage?.sub_title[locale]?.level === 'zero' ? 'dark-red' : 'dark-green'">S</div>
                  <div :class="redactionCoverage?.keywords[locale]?.level === 'zero' ? 'dark-red' : 'dark-green'">K</div>
                </template>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </template>
  </custom-tooltip>
</template>
<script>
import SapTool from "@/components/DataTables/Tools/SapTool/index.vue";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import Tooltip from "@/components/UI/Tooltip/index.vue";
import PreloaderTableSmall from "@/components/UI/PreloaderTableSmall/index.vue";

export default {
  name: 'MetaCoverageIndicator',
  components: {
    PreloaderTableSmall,
    SvgIcon,
    SapTool,
    'custom-tooltip': Tooltip,
  },
  props: {
    coverage: {
      type: Object,
      required: true,
    },
    redactionCoverage: {
      type: Object,
    },
    word: {
      type: String,
      required: true,
    },
    localesTitles: {
      type: Object,
      required: true,
    },
    isKeywordsMode: {
      type: Boolean,
      default: false,
    },
    isRedactionMode: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    stopWords: {
      type: Array,
    }
  },
  methods: {
    isFull(type, locale = null) {
      return this.isCovered(type, 'full', locale);
    },
    isPartial(type, locale = null) {
      return this.isCovered(type, 'partial', locale);
    },
    isCovered(type, coverageLevel, locale) {
      if (locale) {
        return this.computedCoverage[type][locale]?.level === coverageLevel;
      } else {
        return Object.entries(this.computedCoverage[type]).some(([locale, item]) => {
          return Object.keys(this.localesTitles).includes(locale)
              ? item.level === coverageLevel
              : false;
        });
      }
    },
    highlightWord(locale, redaction) {
      let word = this.word;
      const intersectTitle = redaction.title[locale]?.intersect;
      const intersectSubtitle = redaction.sub_title[locale]?.intersect.filter(item => !intersectTitle.includes(item));
      const intersectKeywords = redaction.keywords[locale]?.intersect?.filter(item => !intersectTitle.includes(item) && !intersectSubtitle.includes(item));

      intersectTitle?.forEach((item) => {
        word = word.replace(new RegExp(`\\b${item}\\b`, "gi"), (match) => `<span class="coverage-in-title">${match}</span>`);
      });
      intersectSubtitle?.forEach((item) => {
        word = word.replace(new RegExp(`\\b${item}\\b`, "gi"), (match) => `<span class="coverage-in-subtitle">${match}</span>`);
      });
      intersectKeywords?.forEach((item) => {
        word = word.replace(new RegExp(`\\b${item}\\b`, "gi"), (match) => `<span class="coverage-in-keywords">${match}</span>`);
      });

      return word;
    },
    isFullInTitleAndInSubtitleOrKeywords(locale = null) {
      const phraseWords = this.extractWords();

      if (phraseWords.length > 1) {
        const coverage = this.computedCoverage;

        if (!coverage['title']) {
          return false;
        }

        let foundInTitle =  new Set();
        let foundInOther = new Set();

        if (locale) {
          for (const word of phraseWords) {
            let exactMatch = (list) => list?.includes(word);

            if (exactMatch(coverage['title'][locale]?.intersect)) {
              foundInTitle.add(word);
            } else if (
                exactMatch(coverage['sub_title'][locale]?.intersect) ||
                exactMatch(coverage['keywords'][locale]?.intersect)
            ) {
              foundInOther.add(word);
            }
          }

          return foundInTitle.size > 0 && foundInTitle.size + foundInOther.size === phraseWords.length;
        } else {
          for (const locale in coverage['title']) {
            if (this.localesTitles.hasOwnProperty(locale)) {
              foundInTitle.clear();
              foundInOther.clear();

              for (const word of phraseWords) {
                let exactMatch = (list) => list?.includes(word);

                if (exactMatch(coverage['title'][locale]?.intersect)) {
                  foundInTitle.add(word);
                } else if (
                    exactMatch(coverage['sub_title'][locale]?.intersect) ||
                    exactMatch(coverage['keywords'][locale]?.intersect)
                ) {
                  foundInOther.add(word);
                }
              }

              if (foundInTitle.size > 0 && foundInTitle.size + foundInOther.size === phraseWords.length) {
                return true;
              }
            }
          }
        }
      }

      return false;
    },
    isFullInSubtitleAndKeywords(locale = null) {
      const toCheck = ['sub_title', 'keywords'];
      const phraseWords = this.extractWords();

      if (locale) {
        let foundWords = new Set();
        toCheck.forEach(key => {
          const data = this.computedCoverage[key]?.[locale];

          if (data && data.intersect) {
            data.intersect.forEach(word => {
              if (phraseWords.includes(word)) {
                foundWords.add(word);
              }
            });
          }
        });

        return phraseWords.every(word => foundWords.has(word));
      } else {
        return Object.keys(this.computedCoverage.sub_title || {}).some(loc => {
          if (this.localesTitles.hasOwnProperty(loc)) {
            let foundWords = new Set();

            toCheck.forEach(key => {
              const data = this.computedCoverage[key]?.[loc];

              if (data && data.intersect) {
                data.intersect.forEach(word => {
                  if (phraseWords.includes(word)) {
                    foundWords.add(word);
                  }
                });
              }
            });

            return phraseWords.every(word => foundWords.has(word));
          }

          return false;
        });
      }
    },
    extractWords() {
      const preparedPhrase = this.word.replace(/[^\p{L}\p{N}\s]/gu, '');

      return preparedPhrase.split(' ')
          .filter(word => !this.stopWords.includes(word))
          .filter(item => item !== '');
    },
  },
  computed: {
    titleIndicatorClass() {
      const inBothMetas = this.isFullInTitleAndInSubtitleOrKeywords();

      return {
        full: this.isFull('title'),
        partial: this.isPartial('title'),
        inBothMetas: inBothMetas && this.isPartial('title'),
      }
    },
    subtitleIndicatorClass() {
      const inBothMetas = this.isFullInTitleAndInSubtitleOrKeywords();

      return {
        full: !this.isFull('title') && (this.isFull('sub_title') || this.isFull('keywords') || this.isFullInSubtitleAndKeywords()),
        partial: this.isPartial('sub_title') || this.isPartial('keywords'),
        inBothMetas: inBothMetas && this.isPartial('title'),
      }
    },
    locales() {
      return Object.keys(this.localesTitles);
    },
    coverageForTableIndicators() {
      return this.redactionCoverage ?? this.coverage;
    },
    computedCoverage() {
      return this.isRedactionMode && this.redactionCoverage ? this.redactionCoverage : this.coverage;
    },
  },
}
</script>
<style src="./styles.scss" lang="scss"></style>
