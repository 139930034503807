<script setup>

</script>

<template>
 <span>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.9987 13.6663C3.3168 13.6663 0.332031 10.6815 0.332031 6.99967C0.332031 3.31777 3.3168 0.333008 6.9987 0.333008C10.6806 0.333008 13.6654 3.31777 13.6654 6.99967C13.6654 10.6815 10.6806 13.6663 6.9987 13.6663ZM6.9987 12.333C9.94423 12.333 12.332 9.94521 12.332 6.99967C12.332 4.05415 9.94423 1.66634 6.9987 1.66634C4.05318 1.66634 1.66536 4.05415 1.66536 6.99967C1.66536 9.94521 4.05318 12.333 6.9987 12.333ZM6.33203 3.66634H7.66536V4.99967H6.33203V3.66634ZM6.33203 6.33301H7.66536V10.333H6.33203V6.33301Z" fill="#EE9D24"/>
  </svg>
 </span>
</template>

<style scoped lang="scss">

</style>