<template>
  <div v-if="coverage" class="select-meta-usage-cell">
    <div v-show="keywordsMode" class="select-meta-keywords-cell">
      <div v-for="locale in localesWithTitles" class="locale-block-wrap">
        <div class="locale-block">
          <div v-if="storeCoverage">
            <span v-if="storeCoverage.title[locale.code]?.level === 'full'">
              T
            </span>
              <span v-else-if="storeCoverage.sub_title[locale.code]?.level === 'full'">
              S
            </span>
            <span v-else-if="storeCoverage.keywords[locale.code]?.level === 'full'">
              K
            </span>
          </div>
          <div>
            <template v-if="targetTable === 'words'">
              <span v-if="coverage.title[locale.code]?.level === 'full'">
              T
            </span>
              <span v-else-if="coverage.sub_title[locale.code]?.level === 'full'">
              S
            </span>
              <div v-else>
                <svg-icon v-if="isCovered(locale.code)" icon="icon-check-alt"/>
                <div v-else v-tooltip="{text: isKeywordAddingEnabled(locale.code) ? 'Add missing words to keywords field' : 'Cannot add missing words: the keywords field has reached its maximum character limit', position: 'bottom', classes: ['no-wrap-text']}">
                  <svg-icon class="add-keyword" :class="{disabled : !isKeywordAddingEnabled(locale.code)}" @click="clickKeywordCheckbox(locale.code)" icon="icon-plus-alt"/>
                </div>
              </div>
            </template>
            <template v-else>
              <template v-if="isFullCovered(locale.code)"></template>
              <svg-icon v-else-if="isCovered(locale.code)" icon="icon-check-alt"/>
              <div v-else v-tooltip="{text: isKeywordAddingEnabled(locale.code) ? 'Add missing words to keywords field' : 'Cannot add missing words: the keywords field has reached its maximum character limit', position: 'bottom', classes: ['no-wrap-text']}">
                <svg-icon class="add-keyword" :class="{disabled : !isKeywordAddingEnabled(locale.code)}" @click="clickKeywordCheckbox(locale.code)" icon="icon-plus-alt"/>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <custom-dropdown v-show="!keywordsMode"
                     :close-dropdown="closeDropdownCounter"
                     @dropdown-clicked-outside="dropdownClickedOutside"
                     @dropdown-opened="dropdownOpened"
                     @dropdown-closed="dropdownClosed"
                     classes="select-use-in-tooltip">
      <template v-slot:header>
        <div class="selected-to-use-selected-value" :class="{'full-to-use': isFullToUse()}">
          <span v-if="localeToUseInTitle">
            T
          </span>
          <span v-else-if="localeToUseInSubtitle">
            S
          </span>
          <span v-else-if="localeToUseInKeywords">
            K
          </span>
          <span v-else>
            Use in
          </span>

          <dynamic-image classes="country-flag"
                         v-if="localeToUseInTitle || localeToUseInSubtitle || localeToUseInKeywords"
                         :width="18"
                         :height="14"
                         :size="32"
                         :country-code="localeCountryCode(localeToUseInTitle ?? localeToUseInSubtitle ?? localeToUseInKeywords)?.countryCode"/>
        </div>
      </template>
      <template v-slot:content>
        <div class="select-options">
          <div class="option" v-for="locale in localesWithTitles" :key="locale.code">
            <label :for="locale.code">{{ locale.title }}</label>
            <div class="radio-buttons">
              <radio-button :active="toUseInTitle === locale.code"
                            class="use-in-radio-button"
                            :class="{used: coverage?.title[locale.code]?.level === 'full'}"
                            :id="'toUseInTitle-' + locale.code"
                            mode="inside"
                            :disabled="coverage?.title[locale.code]?.level === 'full'"
                            @change="setToUse(locale.code, 'toUseInTitle')">
                Title
              </radio-button>
              <radio-button :active="toUseInSubtitle === locale.code"
                            class="use-in-radio-button"
                            :class="{used: coverage?.sub_title[locale.code]?.level === 'full'}"
                            :id="'toUseInSubtitle-' + locale.code"
                            mode="inside"
                            :disabled="coverage?.sub_title[locale.code]?.level === 'full'"
                            @change="setToUse(locale.code, 'toUseInSubtitle')">
                Subtitle
              </radio-button>
              <radio-button :active="toUseInKeywords === locale.code"
                            class="use-in-radio-button"
                            :class="{used: coverage?.keywords[locale.code]?.level === 'full'}"
                            :id="'toUseInKeywords-' + locale.code"
                            mode="inside"
                            :disabled="!isKeywordAddingEnabled(locale.code)"
                            @change="setToUse(locale.code, 'toUseInKeywords')">
                Keywords
              </radio-button>
            </div>
          </div>
        </div>
        <base-button height="36px"
                     width="255px"
                     class="btn-standard btn-apply-use-in"
                     border-radius="4px"
                     font-size="16px"
                     :disabled="!isApplyEnabled"
                     @click="apply()">
          Apply
        </base-button>
      </template>
    </custom-dropdown>
  </div>
</template>
<script>
import CustomDropdown from "@/components/UI/CustomDropdown/index.vue";
import RadioButton from "@/components/UI/RadioButton/index.vue";
import {getLocaleCountryCode} from "@/configs/locales-flags";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";

export default {
  name: 'SelectMetaWordUseIn',
  components: {SvgIcon, RadioButton, CustomDropdown},
  emits: ['apply'],
  props: {
    keywordsMode: {
      type: Boolean,
      default: false,
    },
    localesWithTitles: {
      type: Array,
    },
    localeToUseInTitle: {
      type: String,
    },
    localeToUseInSubtitle: {
      type: String,
    },
    word: { //or couple of words(keywords)
      type: String,
      required: true,
    },
    coverage: {
      type: Object,
      required: true,
    },
    storeCoverage: {
      type: Object,
      default: null,
    },
    editableMetas: {
      type: Object,
    },
    targetTable: {
      type: String,
      default: 'keywords',
    },
    stopWords: {
      type: Array,
    },
  },
  data() {
    return {
      toUseInTitle: this.localeToUseInTitle,
      toUseInSubtitle: this.localeToUseInSubtitle,
      toUseInKeywords: null,
      closeDropdownCounter: 0,
      maxKeywordsLength: 100,
      selectOptionsElement: null,
      isDropdownOpen: false,
    };
  },
  mounted() {
    this.selectOptionsElement = this.$el.querySelector('.select-options-wrap');
    this.toUseInTitle = this.localeToUseInTitle;
    this.moveSelectOptionsWrap(this.getSelectOptionsWrapId());
  },
  updated() {
    this.moveSelectOptionsWrap(this.getSelectOptionsWrapId());
  },
  beforeUnmount() {
    const selectOptionsWrapElement = document.getElementById(this.getSelectOptionsWrapId());

    if (selectOptionsWrapElement) {
      document.body.removeChild(selectOptionsWrapElement);
    }
  },
  methods: {
    getSelectOptionsWrapId() {
      return 'select-options-wrap-' + this.word;
    },
    moveSelectOptionsWrap(id) {
      if (document.getElementById(id)) {
        return;
      }

      let el = this.selectOptionsElement;
      el.id = id;
      el.classList.add('select-options-wrap-custom');
      el.style.visibility = 'hidden';
      document.body.appendChild(el);
    },
    dropdownClosed() {
      let el = document.getElementById(this.getSelectOptionsWrapId());
      if (el === null) {
        return;
      }

      el.querySelector('button').style.display = 'none';
      el.style.visibility = 'hidden';

      this.toUseInTitle = this.localeToUseInTitle;
      this.toUseInSubtitle = this.localeToUseInSubtitle;
      this.toUseInKeywords = this.localeToUseInKeywords;
      this.isDropdownOpen = false;
    },
    dropdownClickedOutside(e) {
      if (!this.isDropdownOpen) {
        return;
      }

      if (e.target.closest('.select-options-wrap-custom') === null || e.target.closest('.btn-apply-use-in') !== null) {
        this.dropdownClosed();
        return;
      }

      const radioButton = e.target.closest('.use-in-radio-button');
      if (radioButton !== null) {
        let data = radioButton.id.split('-');
        this.setToUse(data[1], data[0]);
      }
    },
    dropdownOpened() {
      let el = document.getElementById(this.getSelectOptionsWrapId());
      const trigger = this.$el;
      el.style.top = trigger.getBoundingClientRect().top + trigger.getBoundingClientRect().height + window.scrollY + 'px';
      el.style.left = trigger.getBoundingClientRect().left + 'px';
      el.style.visibility = 'visible';
      el.querySelector('button').style.display = 'unset';
      this.isDropdownOpen = true;
    },
    clickKeywordCheckbox(locale) {
      this.setToUse(locale, 'toUseInKeywords');
      this.apply(locale);
    },
    apply(localeCode = null) {
      let phrase = this.word.split(' ')
          .filter(word => !this.stopWords.includes(word.toLowerCase()));

      if (localeCode) {
        const metasByLocale = this.editableMetas?.metas[localeCode];
        const titleWords = metasByLocale?.title.text.toLowerCase().split(/\s+/) || [];
        const subtitleWords = metasByLocale?.sub_title.text.toLowerCase().split(/\s+/) || [];

        phrase = phrase.filter(word => !titleWords.includes(word))
            .filter(word => !subtitleWords.includes(word));
      }

      phrase = phrase.join(' ');

      let wordToUseInTitle = this.toUseInTitle ? phrase : null;
      let wordToUseInSubtitle = this.toUseInSubtitle ? phrase : null;
      let wordToUseInKeywords = this.toUseInKeywords ? phrase : null;
      let locale = this.toUseInTitle || this.toUseInSubtitle || this.toUseInKeywords;
      this.$emit('apply', locale, wordToUseInTitle, wordToUseInSubtitle, wordToUseInKeywords, this.targetTable);
      this.closeDropdownCounter++;
    },
    setToUse(locale, toUseIn) {
      ['toUseInTitle', 'toUseInSubtitle', 'toUseInKeywords'].forEach((key) => {
        this[key] = toUseIn === key ? locale : null;
      });
    },
    localeCountryCode(code) {
      return getLocaleCountryCode(code);
    },
    isKeywordAddingEnabled(localeCode) {
      const currentKeywords = this.editableMetas.metas[localeCode]?.keywords.join(',').replace(/,$/, '');
      const newKeywords = [currentKeywords, this.word].filter(Boolean).join(',');

      return newKeywords.length <= this.maxKeywordsLength;
    },
    isCovered(localeCode) {
      const metasByLocale = this.editableMetas?.metas[localeCode];

      const keywords = metasByLocale?.keywords || [];
      const titleWords = metasByLocale?.title.text.toLowerCase().split(/\s+/) || [];
      const subtitleWords = metasByLocale?.sub_title.text.toLowerCase().split(/\s+/) || [];

      let phrase = this.word
          .split(/\s+/)
          .map(word => word.toLowerCase())
          .filter(word => !this.stopWords.includes(word))
          .filter(word => !titleWords.includes(word))
          .filter(word => !subtitleWords.includes(word));

      return phrase.every(word => keywords.includes(word));
    },
    isFullCovered(localeCode) {
      if (this.coverage.title[localeCode]?.level === 'full' || this.coverage.sub_title[localeCode]?.level === 'full') {
        return true;
      }

      const metas = this.editableMetas?.metas[localeCode];
      const keywords = metas?.keywords || [];
      const title = metas?.title?.text || '';
      const subtitle = metas?.subtitle?.text || '';
      const phrase = this.word.toLowerCase().replace(/[^\p{L}\p{N}\s]/gu, '');
      const words = phrase.split(/\s+/).filter(word => !this.stopWords.includes(word));
      const titleWords = title.toLowerCase().split(/\s+/);
      const subtitleWords = subtitle.toLowerCase().split(/\s+/);
      let keywordsWords = [];

      if (Array.isArray(keywords)) {
        if (Array.isArray(keywords[0])) {
          keywordsWords = keywords[0][0].split(',').map(k => k.trim().toLowerCase());
        } else {
          keywordsWords = keywords.map(k => k.trim().toLowerCase());
        }
      } else if (typeof keywords === 'string') {
        keywordsWords = keywords.split(',').map(k => k.trim().toLowerCase());
      }

      const isPhraseInOneField =
          titleWords.join(' ').includes(phrase) ||
          subtitleWords.join(' ').includes(phrase) ||
          keywordsWords.includes(phrase);

      if (isPhraseInOneField) {
        return true;
      }

      return words.every(word =>
          titleWords.includes(word) ||
          subtitleWords.includes(word) ||
          keywordsWords.includes(word)
      );
    },
    isFullToUse() {
      let metasByLocale = null;

      if (this.localeToUseInTitle) {
        metasByLocale = this.editableMetas.metas[this.localeToUseInTitle];

        return metasByLocale?.title.text.toLowerCase().includes(this.word.toLowerCase());
      } else if (this.localeToUseInSubtitle) {
        metasByLocale = this.editableMetas.metas[this.localeToUseInSubtitle];

        return metasByLocale?.sub_title.text.toLowerCase().includes(this.word.toLowerCase());
      } else if (this.localeToUseInKeywords) {
        metasByLocale = this.editableMetas.metas[this.localeToUseInKeywords];

        if (metasByLocale && metasByLocale?.keywords) {
          return metasByLocale.keywords.some(
              word => typeof word === 'string' && word.toLowerCase() === this.word.toLowerCase(),
          )
        }
      }

      return false;
    }
  },
  computed: {
    isApplyEnabled() {
      return this.toUseInTitle !== this.localeToUseInTitle || this.toUseInSubtitle !== this.localeToUseInSubtitle || this.toUseInKeywords;
    },
    localeToUseInKeywords() {
      let toUse = null;
      Object.keys(this.coverage.keywords).forEach((locale) => {
        if (this.coverage.keywords[locale].level === 'full') {
          toUse = locale;
        }
      });
      return toUse;
    },
  },
  watch: {
    localeToUseInTitle() {
      this.toUseInTitle = this.localeToUseInTitle;
    },
    localeToUseInSubtitle() {
      this.toUseInSubtitle = this.localeToUseInSubtitle;
    },
    localeToUseInKeywords() {
      this.toUseInKeywords = this.localeToUseInKeywords;
    }
  },
}
</script>
<style lang="scss" src="./styles.scss"/>